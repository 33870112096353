
import React, { createContext, useContext } from "react";
import LocalReporter from "./ActionReporter/LocalReporter";
import SentryReporter from "./ActionReporter/SentryReporter";

import { isLocal } from "~/config/env";

export type TService = "Sentry" | "Local";

export const Services: Record<string, TService> = {
  Sentry: "Sentry",
  Local: "Local",
};

export class ActionReporter {
  private reporter: LocalReporter | SentryReporter;

  constructor(service: TService) {
    if (isLocal) {
      this.reporter = new LocalReporter();
    } else {
      switch (service) {
        case Services.Local:
          this.reporter = new LocalReporter();
          break;
        case Services.Sentry:
          this.reporter = new SentryReporter();
          break;
        default:
          throw new Error(`Unknown service: ${service}`);
      }
    }
  }

  notify(message: string, { context }: { context?: Record<string, any> } = {}) {
    return this.reporter.notify(message, { context });
  }

  setContext(context: Record<string, any>) {
    return this.reporter.setContext(context);
  }

  resetContext() {
    return this.reporter.resetContext();
  }

  errorBoundary({
    worker,
    children,
  }: {
    worker: ActionReporter;
    children: React.ReactNode;
  }): any {
    return worker.reporter.errorBoundary({ children });
  }

  reportGraphQLError(error: any, operation: any) {
    const message = 'GraphQL Error';
    const context = { error, operation };
    this.notify(message, { context });
  }
}


export const ActionReporterContext = createContext<ActionReporter>(
  new ActionReporter(Services.Local)
);

export const useActionReporter = () => useContext(ActionReporterContext);

export default ActionReporter;
