import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  NetworkStatus,
  NormalizedCacheObject,
  Observable,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { Subscription } from "zen-observable-ts";
import { handleGlobalError } from '../components/GlobalErrorHandler';
import { reportingService } from "../config/actionReporter";
import { devLogger, isDevelopment, voimaVersion } from "../config/env";
import { getServerUrl } from "../helpers";
import ActionReporter from "./ActionReporter";
import { GraphQLError } from './GraphQLError';

function mergePages(existing, incoming) {
  const existingEdges = existing?.edges || [];
  return {
    edges: [...existingEdges, ...incoming.edges],
    pageInfo: incoming.pageInfo,
    totalCount: incoming.totalCount,
  };
}

function mergeLibrary(existing, incoming) {
  const incomingNodes = incoming?.nodes || [];
  const existingNodes = existing?.nodes || [];
  const incomingEdges = incoming?.edges || [];
  const existingEdges = existing?.edges || [];
  const nodes = new Set([...existingNodes, ...incomingNodes]);
  const edges = new Set([...existingEdges, ...incomingEdges]);
  return {
    nodes: [...nodes],
    edges: [...edges],
    pageInfo: incoming.pageInfo,
    totalCount: incoming.totalCount,
  };
}

export type IPaginatedResult<T> = {
  nodes?: T[];
  edges?: {
    node?: T;
    cursor?: string;
  }[];
  totalCount?: number;
  pageInfo?: {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    startCursor?: string;
    endCursor?: string;
  };
};

export function usePaginatedQuery<T, U>(
  queryName: string,
  query: DocumentNode,
  variables: T,
  fetchMore = ({ args, pageInfo, fetchMore }) =>
    fetchMore({
      variables: {
        first: args.first,
        after: pageInfo.endCursor,
      },
    })
) {
  const args = variables as unknown as OperationVariables;
  const result = useQuery(query, {
    variables: { ...(args as OperationVariables) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const pageInfo = result?.data?.[queryName]?.pageInfo;

  return {
    ...(result?.data?.[queryName] as IPaginatedResult<U>),
    loading: result.networkStatus === NetworkStatus.loading,
    fetching: result.networkStatus === NetworkStatus.fetchMore,
    error: result.error,
    pageInfo: pageInfo,
    fetchMore: () => fetchMore({ args, pageInfo, fetchMore: result.fetchMore }),
  };
}

/**
 * ApolloClientProxy class
 *
 * This class is responsible for setting up the Apollo Client with custom error handling and reporting.
 * The main goal is to catch and report unhandled GraphQL errors to Sentry and provide a report of the error in the UI.
 *
 * Related components:
 * - ActionReporter: Used for reporting errors to the configured service (Sentry, Honeybadger, or Local)
 * - CustomErrorFallback: UI component for displaying error messages to the user
 * - ClientProxy: Parent class that utilizes this ApolloClientProxy
 */
export class ApolloClientProxy {
  fetchAccessToken: () => string;
  fetchLocale: () => string;
  onAuthenticationError: () => void;

  client: ApolloClient<NormalizedCacheObject>;

  private actionReporter: ActionReporter;

  /**
   * Constructor for ApolloClientProxy
   *
   * Sets up the Apollo Client with custom links for error handling, authentication, and localization.
   * Integrates Sentry for error tracking and uses ActionReporter for error reporting.
   *
   * @param {Object} options - Configuration options
   * @param {Function} options.fetchAccessToken - Function to fetch the access token
   * @param {Function} options.fetchLocale - Function to fetch the current locale
   * @param {Function} options.onAuthenticationError - Function to handle authentication errors
   * @param {ApolloLink} options.additionalLink - Additional Apollo Link to include in the chain
   */
  constructor({
    fetchAccessToken,
    fetchLocale,
    onAuthenticationError,
    additionalLink,
  }: {
    fetchAccessToken?: () => string;
    fetchLocale?: () => string;
    onAuthenticationError?: () => void;
    additionalLink?: ApolloLink;
  }) {
    this.fetchAccessToken = fetchAccessToken || (() => "");
    this.fetchLocale = fetchLocale || (() => "fi");
    this.onAuthenticationError = onAuthenticationError || (() => {});

    this.actionReporter = new ActionReporter(reportingService);

    const sentryLink = new ApolloLink((operation, forward) => {
      const startTime = Date.now();
      return forward(operation).map((response) => {
        const endTime = Date.now();
        const duration = endTime - startTime;

        Sentry.addBreadcrumb({
          category: 'graphql',
          message: `GraphQL operation: ${operation.operationName}`,
          data: {
            operationName: operation.operationName,
            variables: operation.variables,
            duration,
          },
        });

        if (response.errors) {
          response.errors.forEach((error) => {
            Sentry.captureException(new Error(error.message), {
              tags: { operationName: operation.operationName },
              extra: {
                graphqlError: error,
                operationVariables: operation.variables,
              },
            });
          });
        }

        return response;
      });
    });

    const errorLink = this.createErrorLink();

    const localeLink = new ApolloLink((operation) =>
      this.getClientLink({ operation }).request(operation)
    );

    const authLink = new ApolloLink((operation) =>
      this.getClientLink({ operation }).request(operation)
    );

    const httpLink = new HttpLink({
      uri: getServerUrl("/graphql"),
      credentials: "omit",
    });

    const link = ApolloLink.from([
      sentryLink,
      errorLink,
      additionalLink,
      localeLink,
      authLink,
      httpLink
    ].filter(Boolean) as ApolloLink[]);  // Add type assertion here

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  /**
   * Creates a new Apollo Client instance
   *
   * This method is used when a new client needs to be created with a custom cache.
   * It uses the same link configuration as the main client.
   *
   * @param {Object} options - Configuration options
   * @param {InMemoryCache} options.cache - Custom cache for the new client
   * @returns {ApolloClient} New Apollo Client instance
   */
  createClient({
    cache,
  }: {
    cache?: InMemoryCache;
  } = {}) {
    return new ApolloClient({
      link: new ApolloLink((operation) =>
        this.getClientLink({ operation }).request(operation)
      ),
      cache: cache || this.createCache({}),
      connectToDevTools: isDevelopment,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          errorPolicy: "all",
        },
      },
    });
  }

  /**
   * Creates the cache for Apollo Client
   *
   * Sets up the InMemoryCache with custom merge functions for various query types.
   * This ensures proper handling of paginated and nested data in the Apollo cache.
   *
   * @returns {InMemoryCache} Configured InMemoryCache instance
   */
  createCache({}: {}) {
    const cache = new InMemoryCache({
      possibleTypes: {},
      typePolicies: {
        DocumentationLibrary: {
          fields: {
            projectStages: {
              merge(existing, incoming, { mergeObjects }) {
                // return mergeObjects(existing, incoming);
                return incoming;
              },
            },
          },
        },
        ProjectStage: {
          merge: true,
        },
        KnowledgeArea: {
          merge: true,
        },
        ContentCategory: {
          merge: true,
        },
        Query: {
          fields: {
            documentationLibraries: {
              keyArgs: ["query"],
              merge(existing, incoming) {
                return mergeLibrary(existing, incoming);
              },
            },
            projectStages: {
              keyArgs: ["query"],
              merge(existing, incoming) {
                return mergeLibrary(existing, incoming);
              },
            },
            workspaces: {
              keyArgs: [
                "order",
                "onlyOwned",
                "departments",
                "unitCodes",
                "managerNames",
                "clients",
                "clientRepresentativeNames",
                "worksites",
                "location",
                "riskLevels",
                "statuses",
                "query",
                "lifecycleStatus",
                "onlyMember",
              ],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            frameAgreements: {
              keyArgs: [
                "query",
                "onlyActive",
                "departments",
                "organizations",
                "clientContactPersonNames",
                "customerNames",
                "ownerNames",
                "number",
                "order",
              ],
              merge(existing = [], incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergePages(existing, incoming);
              },
            },
            subsidiaries: {
              keyArgs: ["name"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            customers: {
              keyArgs: ["name"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            priceLists: {
              keyArgs: ["name", "subsidiaryId"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            offers: {
              keyArgs: ["query"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            projects: {
              keyArgs: ["query"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            projectReferenceEmployees: {
              keyArgs: ["projectIds", "projectStartDate", "projectEndDate"],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            referenceGroups: {
              keyArgs: [
                "name",
                "onlyOwned",
                "keywords",
                "informationSystemSkills",
              ],
              merge(existing, incoming) {
                return mergePages(existing, incoming);
              },
            },
            worksites: {
              keyArgs: (args, context) => {
                if (args?.ids) {
                  return ["ids"];
                }

                return ["name"];
              },
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergePages(existing, incoming);
              },
            },
            buildingWorksites: {
              keyArgs: [
                "name",
                "address",
                "buildingIdentifier",
                "buildingName",
                "propertyIdentifier",
                "buildingClassificationCodes",
                "onlyOwned",
                "onlyMember",
                "withoutBuildingIdentifier",
              ],
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergePages(existing, incoming);
              },
            },
            buildings: {
              keyArgs: ["ids"],
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergePages(existing, incoming);
              },
            },
            searchBuildings: {
              keyArgs: ["address", "propertyIdentifier"],
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergePages(existing, incoming);
              },
            },
            serviceAreas: {
              keyArgs: (args, context) => {
                if (context["serviceAreasWithLibraries"]) {
                  return ["serviceAreasWithLibraries"];
                }

                return ["first"];
              },
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergeLibrary(existing, incoming);
              },
            },
            workspaceMembershipRequests: {
              keyArgs: ["workspaceId"],
              merge(existing, incoming) {
                if (incoming?.pageInfo && !incoming.pageInfo.hasPreviousPage) {
                  return incoming;
                }
                return mergeLibrary(existing, incoming);
              },
            },
          },
        },
      },
    });
    if (isDevelopment) {
      Object.assign(window, { graphqlCache: cache });
    }
    return cache;
  }

  /**
   * Creates an Apollo Link that adds the access token to requests
   *
   * This link ensures that all GraphQL requests include the current access token in the Authorization header.
   * It also adds the current locale and version information to the request headers.
   *
   * @returns {ApolloLink} Apollo Link for token and header management
   */
  createLinkWithToken = ({}: {}) =>
    new ApolloLink(
      (operation, forward) =>
        new Observable((observer) => {
          let handle: Subscription;
          Promise.resolve(operation)
            .then((operation) => {
              const headers = operation.getContext().headers || {};
              devLogger.log("ApolloLink token", this.fetchAccessToken());
              operation.setContext({
                headers: {
                  ...headers,
                  ...{
                    Authorization: `Bearer ${this.fetchAccessToken()}`,
                  },
                },
              });
              return operation;
            })
            .then((operation) => {
              const locale = this.fetchLocale?.();
              if (locale) {
                const headers = operation.getContext().headers || {};
                operation.setContext({
                  headers: {
                    ...headers,
                    ...{ "Accept-Language": locale },
                  },
                });
              }
              return operation;
            })
            .then((operation) => {
              const headers = operation.getContext().headers || {};
              operation.setContext({
                headers: {
                  ...headers,
                  ...{ "voima-version": voimaVersion },
                },
              });
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch(observer.error.bind(observer));
          return () => {
            if (handle) handle.unsubscribe();
          };
        })
    );

  /**
   * Creates an Apollo Link for error handling
   *
   * This link catches GraphQL and network errors, logs them, and triggers the authentication error handler if necessary.
   * It works in conjunction with the Sentry link and ActionReporter for comprehensive error tracking and reporting.
   *
   * @returns {ApolloLink} Apollo Link for error handling
   */
  createErrorLink = () => {
    return onError(({ graphQLErrors, networkError, operation }) => {

      // lets report the network error first, as that seems most important
      if (networkError) {
        console.error("Network Error", networkError);
        handleGlobalError(networkError);
      }

      if (graphQLErrors) {
      // guard function because we are handling the error directly in this case
        if (graphQLErrors.some((error) => error.extensions?.code === "NOT_AUTHENTICATED")) {
        this.onAuthenticationError();
        return;
        }

        // if we make it to the end, then report out all of the errors

        graphQLErrors.forEach((error) => {
          const graphQLError = new GraphQLError(
            error.message,
            operation.operationName,
            operation.variables,
            error.path?.map(String),
            error.extensions
          );

          console.error("GraphQL Error", graphQLError);

          // Use the global error handler with the custom GraphQLError
          handleGlobalError(graphQLError);
        });

    }
    });
  };

  /**
   * Creates an HTTP link for the Apollo Client
   *
   * Sets up the connection to the GraphQL server with the appropriate URL and credentials policy.
   *
   * @param {string} endpoint - The GraphQL endpoint path
   * @returns {HttpLink} Configured HTTP link for Apollo Client
   */
  createHttpLink = (endpoint: string) =>
    new HttpLink({
      uri: getServerUrl(endpoint),
      credentials: "omit",
    });

  /**
   * Creates the main link chain for the Voima GraphQL API
   *
   * Combines error handling, authentication, and HTTP links for the main API.
   *
   * @param {Object} options - Configuration options
   * @param {string} options.graphName - The name of the graph to connect to
   * @returns {ApolloLink} Combined Apollo Link for the Voima API
   */
  getVoimaLink = ({ graphName }: { graphName: string }) =>
    ApolloLink.from([
      this.createErrorLink(),
      this.createLinkWithToken({}),
      this.createHttpLink(`/graphql/${graphName}`),
    ]);

  /**
   * Determines the appropriate client link based on the operation context
   *
   * Currently, this always returns the Voima link, but it's set up to potentially handle different client types in the future.
   *
   * @param {Object} options - Configuration options
   * @param {any} options.operation - The GraphQL operation being executed
   * @returns {ApolloLink} The appropriate Apollo Link for the given operation
   */
  getClientLink({ operation }: { operation: any }) {
    switch (operation.getContext()?.clientName) {
      default:
        return this.getVoimaLink({
          graphName: "web",
        });
    }
  }

}
